.Footer {
    text-align: center;
    height: 3vh;
    background-color: black;
    color: white;
    position: fixed;
    bottom: 0px;
    width: 100%;
    z-index: 9999;
}
